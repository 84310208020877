<template>
	<footer class="footer" v-if="computedValue === `ko`">
		<div class="footer--1">
			<router-link to="/" class="logo--fcts"
				><img :src="logoUrl"
			/></router-link>
			<div class="footer__sitemapbox">
				<ul class="sitemap__list">
					<li>
						<router-link to="/service" class="sitemap__link--main"
							>서비스</router-link
						>
					</li>
					<li><router-link to="/servicecrypto">암호 스키마</router-link></li>
					<li><router-link to="/servicesecurity">모듈러스</router-link></li>
					<li><router-link to="/serviceBiMining">BI Mining</router-link></li>
					<li>
						<router-link to="/servicevaccine">백신 FC-Anti Virus</router-link>
					</li>
					<li>
						<router-link to="/serviceCryptaLedger">CryptaLedger</router-link>
					</li>
				</ul>
				<ul class="sitemap__list">
					<li>
						<router-link to="/solution" class="sitemap__link--main"
							>솔루션</router-link
						>
					</li>
					<li><router-link to="/solutioncrypto">암호 스키마</router-link></li>
					<li>
						<router-link to="/solutionvaccine">백신 FC-Anti Virus</router-link>
					</li>
				</ul>
				<ul class="sitemap__list">
					<li>
						<router-link to="/warning" class="sitemap__link--main"
							>요금</router-link
						>
					</li>
					<li><router-link to="/warning">암호 스키마</router-link></li>
					<li><router-link to="/pricingsecurity">모듈러스</router-link></li>
					<!-- 요금페이지 들어가면 router 바꿔야합니당! -->
					<li><router-link to="/serviceBiMining">BI Mining</router-link></li>
					<li><router-link to="/warning">백신 FC-Anti Virus</router-link></li>
				</ul>
				<ul class="sitemap__list">
					<li>
						<router-link to="/userguide" class="sitemap__link--main"
							>제품 사용방법</router-link
						>
					</li>
					<li><router-link to="/userguide">이용안내</router-link></li>
					<li><router-link to="/warning">개발자 페이지</router-link></li>
				</ul>
				<ul class="sitemap__list">
					<li>
						<router-link to="/inquiry" class="sitemap__link--main"
							>고객센터</router-link
						>
					</li>
					<li><router-link to="/inquiry">1:1문의</router-link></li>
					<li><router-link to="/faq">자주 하는 질문</router-link></li>
				</ul>
			</div>
		</div>
		<div class="footerdivider--grey"></div>
		<div class="FooterTofot">
			<div class="footer__section--2">
				<div class="textlogo--fourchains">
					<span>포체인스 주식회사</span>
				</div>
				<div class="companyinfo">
					<div class="companyinfo__terms">
						<router-link to="/terms" target="_blank">이용약관</router-link>
						<router-link to="/privacy" target="_blank"
							>개인정보 처리방침</router-link
						>
					</div>
					<ul>
						<li>상호 : 포체인스 주식회사</li>
						<li>대표 : 이정훈</li>
						<li>대표전화 : 031-995-6409</li>
						<li>이메일 : info@four-chains.com</li>
						<li>사업자 등록번호 : 475-85-02306</li>
						<li>본사 : 대구광역시 중구 국채보상로 558-1, 2층 90호</li>
						<li>
							R&D 연구센터 : 경기도 고양시 일산서구 킨텍스로 217-59 제2킨텍스
							오피스동 703호
						</li>
						<li>copyright 2021 포체인스 (주) All rights reserved</li>
					</ul>
				</div>
			</div>
			<div class="custom-select" @click="toggleDropdown">
				<div class="selected-option">{{ selectedOption }}</div>
				<div class="dropdown" :class="{ show: isOpen }">
					<div
						v-for="option in options"
						:key="option.value"
						@click="selectOption(option)"
					>
						{{ option.label }}
					</div>
				</div>
			</div>
		</div>
	</footer>
	<footer class="footer" v-else-if="computedValue === `en`">
		<div class="footer--1">
			<router-link to="/" class="logo--fcts"
				><img :src="logoUrl"
			/></router-link>
			<div class="footer__sitemapbox">
				<ul class="sitemap__list">
					<li>
						<router-link to="/service" class="sitemap__link--main"
							>Service</router-link
						>
					</li>
					<li><router-link to="/servicecrypto">Crypto Schema</router-link></li>
					<li><router-link to="/servicesecurity">Modulus</router-link></li>
					<li><router-link to="/serviceBiMining">BI Mining</router-link></li>
					<li>
						<router-link to="/servicevaccine"
							>Vaccine FC-Anti Virus</router-link
						>
					</li>
					<li>
						<router-link to="/serviceCryptaLedger">CryptaLedger</router-link>
					</li>
				</ul>
				<ul class="sitemap__list">
					<li>
						<router-link to="/solution" class="sitemap__link--main"
							>Solution</router-link
						>
					</li>
					<li><router-link to="/solutioncrypto">Crypto Schema</router-link></li>
					<li>
						<router-link to="/solutionvaccine"
							>Vaccine FC-Anti Virus</router-link
						>
					</li>
				</ul>
				<ul class="sitemap__list">
					<li>
						<router-link to="/warning" class="sitemap__link--main"
							>Pricing</router-link
						>
					</li>
					<li><router-link to="/warning">Crypto Schema</router-link></li>
					<li><router-link to="/pricingsecurity">Modulus</router-link></li>
					<!-- 요금페이지 들어가면 router 바꿔야합니당! -->
					<li><router-link to="/serviceBiMining">BI Mining</router-link></li>
					<li>
						<router-link to="/warning">Vaccine FC-Anti Virus</router-link>
					</li>
				</ul>
				<ul class="sitemap__list">
					<li>
						<router-link to="/userguide" class="sitemap__link--main"
							>Product Usag</router-link
						>
					</li>
					<li><router-link to="/userguide">User Guide</router-link></li>
					<li><router-link to="/warning">Developer Page</router-link></li>
				</ul>
				<ul class="sitemap__list">
					<li>
						<router-link to="/inquiry" class="sitemap__link--main"
							>Customer Center</router-link
						>
					</li>
					<li><router-link to="/inquiry">1:1 Contact</router-link></li>
					<li>
						<router-link to="/faq">Frequently Asked Questions</router-link>
					</li>
				</ul>
			</div>
		</div>
		<div class="footerdivider--grey"></div>
		<div class="FooterTofot">
			<div class="footer__section--2">
				<div class="textlogo--fourchains">
					<span>FourChains Inc.</span>
				</div>
				<div class="companyinfo">
					<div class="companyinfo__terms">
						<router-link to="/terms" target="_blank"
							>Terms of Service</router-link
						>
						<router-link to="/privacy" target="_blank">
							Privacy Policy
						</router-link>
					</div>
					<ul>
						<li>Buseness Name: FourChains Inc.</li>
						<li>CEO: Jeong-hoon Lee</li>
						<li>Phone: 031-995-6409</li>
						<li>Email: info@four-chains.com</li>
						<li>Business Registration Number: 457-85-02306</li>
						<li>
							Headpuarters : 558-1, 2F, 90ho, Gukchaebosang-ro, Jung-gu, Daegu,
							South korea
						</li>
						<li>
							R&D Center:703ho, Office Building 2, KINTEX, 217-59 Ilsanseo-gu,
							Goyang-si, Gyeonggi-do, South Korea
						</li>
						<li>Copyright 2021 FourChains Inc. All rights reserved.</li>
					</ul>
				</div>
			</div>
			<div class="custom-select" @click="toggleDropdown">
				<div class="selected-option">{{ selectedOption }}</div>
				<div class="dropdown" :class="{ show: isOpen }">
					<div
						v-for="option in options"
						:key="option.value"
						@click="selectOption(option)"
					>
						{{ option.label }}
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import logoUrl from '@/assets/images/new-fctslogo.png';
export default {
	data() {
		return {
			logoUrl,
			isOpen: false,
			selectedOption: 'FAMILY SITE',
			options: [
				{ label: 'PROBE', value: 'https://probe.kr/about' },
				{ label: 'FourChains', value: 'https://www.four-chains.com/' },
				{ label: 'MODULUS', value: 'https://fcts-mod.com/' },
				{ label: 'Crypto Blockchain', value: 'https://cryptoblockchain.im/' },
				{ label: 'EHT', value: 'https://eht.im/' },
				{ label: 'PixeLock', value: 'https://pixelock.im/' },
				{ label: 'StrongBox', value: 'https://strongbox.im/' },
			],
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		toggleDropdown() {
			this.isOpen = !this.isOpen;
		},
		selectOption(option) {
			if (option.label === 'FAMILY SITE') {
				return; // 선택된 옵션이 "FAMILY SITE"인 경우 아무 작업을 하지 않음
			}

			// 외부 링크로 이동
			window.open(option.value, '_blank');

			//이동 후 1초 후에 "FAMILY SITE"로 제목 변경
			this.isOpen = false;
			setTimeout(() => {
				this.selectedOption = 'FAMILY SITE';
			}, 1000);
		},
	},
};
</script>

<style scoped></style>
