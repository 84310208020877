<template>
	<div v-if="computedValue === `ko`">
		<!-- 데스크탑에서의 navbar -->
		<nav class="navbar__pc">
			<div class="navbar_menu1">
				<div class="navbar_logo">
					<router-link to="/" class="logo"><img :src="logoUrl" /></router-link>
				</div>
				<ul class="navbar_menu">
					<li
						:class="{ active: isActive }"
						class="menu_link"
						@click="showSubmenu1"
					>
						서비스
						<ul
							class="menu_sub_list"
							v-show="this.submenu1"
							id="sub_item--large"
						>
							<li>
								<router-link to="/service" class="sub_item" id="sub_item--bold"
									>서비스</router-link
								>
							</li>
							<li>
								<router-link to="/servicecrypto" class="sub_item"
									>암호 스키마</router-link
								>
							</li>
							<li>
								<router-link to="/servicesecurity" class="sub_item"
									>모듈러스</router-link
								>
							</li>
							<li>
								<router-link to="/serviceBiMining" class="sub_item"
									>BI-Mining</router-link
								>
							</li>
							<li>
								<router-link to="/servicevaccine" class="sub_item"
									>백신 FC-Anti Virus</router-link
								>
							</li>
							<li>
								<router-link to="/serviceCryptaLedger" class="sub_item"
									>CryptaLedger</router-link
								>
							</li>
							<li>
								<router-link to="/serviceData" class="sub_item"
									>데이터 암호화 취약점 분석 및 컨설팅</router-link
								>
							</li>
						</ul>
					</li>
					<li
						:class="{ active: isActive2 }"
						class="menu_link"
						@click="showSubmenu2"
					>
						솔루션
						<ul
							class="menu_sub_list"
							v-show="this.submenu2"
							id="sub_item--large"
						>
							<li>
								<router-link to="/solutioncrypto" class="sub_item"
									>암호 스키마</router-link
								>
							</li>
							<li>
								<router-link to="/solutionvaccine" class="sub_item"
									>백신 FC-Anti Virus</router-link
								>
							</li>
						</ul>
					</li>
					<li
						:class="{ active: isActive3 }"
						class="menu_link"
						@click="showSubmenu3"
					>
						요금
						<ul
							class="menu_sub_list"
							v-show="this.submenu3"
							id="sub_item--large"
						>
							<li>
								<router-link to="/pricing" class="sub_item"
									><strong>요금</strong></router-link
								>
							</li>
							<!-- <li>
								<router-link to="/pricingcrypto" class="sub_item"
									>암호 스키마</router-link
								>
							</li> -->
							<li>
								<router-link to="/pricingsecurity" class="sub_item"
									>모듈러스</router-link
								>
							</li>
							<li>
								<router-link to="/pricingbimining" class="sub_item"
									>BI Mining</router-link
								>
							</li>
							<!-- <li>
								<router-link to="/pricingvaccine" class="sub_item"
									>백신 FC-Anti Virus</router-link
								>
							</li> -->
						</ul>
					</li>
					<li
						:class="{ active: isActive4 }"
						class="menu_link"
						@click="showSubmenu4"
					>
						제품 사용방법
						<ul class="menu_sub_list" v-show="this.submenu4" id="sub_item--sm">
							<li>
								<router-link to="/userguide" class="sub_item"
									>이용안내</router-link
								>
							</li>
							<li>
								<router-link to="/warning" class="sub_item"
									>개발자 페이지</router-link
								>
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div class="navbar_menu2">
				<ul class="menu_cs">
					<li
						:class="{ active: isActive5 }"
						@click="showCsmenu"
						class="menu_cs--title"
					>
						고객센터
						<ul class="menu_sub_list" v-show="csMenu">
							<li>
								<router-link to="/inquiry" class="sub_item"
									>문의하기</router-link
								>
							</li>
							<li>
								<router-link to="/faq" class="sub_item"
									>자주 하는 질문</router-link
								>
							</li>
						</ul>
					</li>
				</ul>

				<!-- 로그인 성공 시 헤더에 표시 및 분기처리 -->
				<!-- 1. 로그인 시 -->
				<template v-if="isUserlogin">
					<span
						class="mainheader__text--com"
						v-if="this.$store.state.userCom == 1"
						>기업 회원
					</span>
					<span
						class="mainheader__text--personal"
						v-if="this.$store.state.userCom == 0"
						>개인 회원
					</span>
					<span class="mainheader__text--id">{{ $store.state.userid }}</span
					><span>&nbsp;&nbsp;님&nbsp;&nbsp;</span>
					<router-link to="/mypage" class="mainheader__text"
						>마이 페이지</router-link
					>
					<span>&nbsp;｜&nbsp;</span>
					<a href="javascript:;" @click="logoutUser" class="mainheader__text"
						>로그아웃</a
					>
				</template>

				<!-- 2. 로그아웃 시 -->
				<template v-else>
					<router-link to="/login"
						><button id="btn-login" class="btn--sm btn--border-white">
							로그인
						</button></router-link
					>
					<router-link to="/signup"
						><button id="btn-signup" class="btn--sm btn--fill-white">
							회원가입
						</button></router-link
					>
				</template>
				<template>
					<div class="lang-select-box">
						<h3
							class="lang-select"
							:class="{ active: computedValue === 'ko' }"
							@click="changeLang('ko')"
						>
							KOR
						</h3>
						&nbsp; | &nbsp;
						<h3
							class="lang-select"
							:class="{ active: computedValue === 'en' }"
							@click="changeLang('en')"
						>
							ENG
						</h3>
					</div>
				</template>
			</div>
		</nav>
		<div class="global-overlay" v-show="menuActive"></div>
		<!-- 태블릿, 모바일에서의 navbar -->
		<nav class="navbar__mb">
			<div class="navbar_menu1">
				<div class="navbar_logo">
					<router-link to="/" class="logo"><img :src="logoUrl" /></router-link>
					<!-- 태블릿, 모바일에서의 햄버거 메뉴 -->
					<burger-menu
						@toggle-menu="menuActive = !menuActive"
						:active="menuActive"
					></burger-menu>
				</div>
				<div id="navbarNav" v-show="menuActive">
					<!-- 한글 / 영문 번역 추가 -->
					<template>
						<div class="lang-select-box">
							<h3
								class="lang-select"
								:class="{ active: computedValue === 'ko' }"
								@click="changeLang('ko')"
							>
								KOR
							</h3>
							&nbsp; | &nbsp;
							<h3
								class="lang-select"
								:class="{ active: computedValue === 'en' }"
								@click="changeLang('en')"
							>
								ENG
							</h3>
						</div>
					</template>
					<!--  -->

					<div class="navbar-nav">
						<div class="main_area">서비스</div>
						<div class="sub_area">
							<router-link to="/service"><strong>서비스</strong></router-link>
							<router-link to="/servicecrypto">암호 스키마</router-link>
							<router-link to="/servicesecurity">모듈러스</router-link>
							<router-link to="/serviceBiMining">BI Mining</router-link>
							<router-link to="/servicevaccine">백신 FC-Anti Virus</router-link>
							<router-link to="/serviceCryptaLedger">CryptaLedger</router-link>
							<router-link to="/serviceData"
								>데이터 암호화 취약점 분석 및 컨설팅</router-link
							>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">솔루션</div>
						<div class="sub_area">
							<router-link to="/solutioncrypto">암호 스키마</router-link>
							<router-link to="/solutionvaccine"
								>백신 FC-Anti Virus</router-link
							>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">요금</div>
						<div class="sub_area">
							<router-link to="/pricing"><strong>요금</strong></router-link>
							<!-- <router-link to="/pricingcrypto">암호 스키마</router-link> -->
							<router-link to="/pricingsecurity">모듈러스</router-link>
							<router-link to="/pricingbimining">BI Mining</router-link>
							<!-- <router-link to="/pricingvaccine">백신 FC-Anti Virus</router-link> -->
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">제품 사용방법</div>
						<div class="sub_area">
							<router-link to="/userguide">이용안내</router-link>
							<router-link to="/warning">개발자 페이지</router-link>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">Modulus</div>
						<div class="sub_area">
							<router-link to="/warning">Modulus</router-link>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">고객센터</div>
						<div class="sub_area">
							<router-link to="/inquiry">문의하기</router-link>
							<router-link to="/faq">자주 하는 질문</router-link>
						</div>
					</div>

					<!-- 로그인 성공 시 헤더에 표시 및 분기처리 -->
					<!-- 1. 로그인 시 -->
					<template v-if="isUserlogin">
						<div class="login-info">
							<router-link to="/mypage" class="mainheader__text"
								>마이 페이지</router-link
							>
							<span>&nbsp;&nbsp;｜&nbsp;&nbsp;</span>
							<a
								href="javascript:;"
								@click="logoutUser"
								class="mainheader__text"
								>로그아웃</a
							>
						</div>
					</template>

					<!-- 2. 로그아웃 시 -->
					<template v-else>
						<div class="navbar_buttons">
							<router-link to="/login"
								><button id="btn__login" class="btn--extra btn--border-white">
									로그인
								</button></router-link
							>
							<router-link to="/signup"
								><button id="btn__signup" class="btn--extra btn--fill-accent">
									회원가입
								</button></router-link
							>
						</div>
					</template>
				</div>
			</div>
		</nav>
	</div>
	<div v-else-if="computedValue === `en`">
		<!-- 데스크탑에서의 navbar -->
		<nav class="navbar__pc">
			<div class="navbar_menu1">
				<div class="navbar_logo">
					<router-link to="/" class="logo"><img :src="logoUrl" /></router-link>
				</div>
				<ul class="navbar_menu">
					<li
						:class="{ active: isActive }"
						class="menu_link"
						@click="showSubmenu1"
					>
						Service
						<ul
							class="menu_sub_list"
							v-show="this.submenu1"
							id="sub_item--large"
						>
							<li>
								<router-link to="/service" class="sub_item" id="sub_item--bold"
									>Service</router-link
								>
							</li>
							<li>
								<router-link to="/servicecrypto" class="sub_item"
									>Crypto Schema</router-link
								>
							</li>
							<li>
								<router-link to="/servicesecurity" class="sub_item"
									>Modulus</router-link
								>
							</li>
							<li>
								<router-link to="/serviceBiMining" class="sub_item"
									>BI-Mining</router-link
								>
							</li>
							<li>
								<router-link to="/servicevaccine" class="sub_item"
									>Vaccine FC-Anti Virus</router-link
								>
							</li>
							<li>
								<router-link to="/serviceCryptaLedger" class="sub_item"
									>CryptaLedger</router-link
								>
							</li>
							<li>
								<router-link to="/serviceData" class="sub_item"
									>Data Encryption Vulnerability Analysis and
									Consulting</router-link
								>
							</li>
						</ul>
					</li>
					<li
						:class="{ active: isActive2 }"
						class="menu_link"
						@click="showSubmenu2"
					>
						Solution
						<ul
							class="menu_sub_list"
							v-show="this.submenu2"
							id="sub_item--large"
						>
							<li>
								<router-link to="/solutioncrypto" class="sub_item"
									>Crypto Schema</router-link
								>
							</li>
							<li>
								<router-link to="/solutionvaccine" class="sub_item"
									>Vaccine FC-Anti Virus</router-link
								>
							</li>
						</ul>
					</li>
					<li
						:class="{ active: isActive3 }"
						class="menu_link"
						@click="showSubmenu3"
					>
						Pricing
						<ul
							class="menu_sub_list"
							v-show="this.submenu3"
							id="sub_item--large"
						>
							<li>
								<router-link to="/pricing" class="sub_item"
									><strong>Pricing</strong></router-link
								>
							</li>
							<!-- <li>
								<router-link to="/pricingcrypto" class="sub_item"
									>암호 스키마</router-link
								>
							</li> -->
							<li>
								<router-link to="/pricingsecurity" class="sub_item"
									>Modulus</router-link
								>
							</li>
							<li>
								<router-link to="/pricingbimining" class="sub_item"
									>BI Mining</router-link
								>
							</li>
							<!-- <li>
								<router-link to="/pricingvaccine" class="sub_item"
									>백신 FC-Anti Virus</router-link
								>
							</li> -->
						</ul>
					</li>
					<li
						:class="{ active: isActive4 }"
						class="menu_link"
						@click="showSubmenu4"
						style="width: 110px"
					>
						Product Usag
						<ul class="menu_sub_list" v-show="this.submenu4" id="sub_item--sm">
							<li>
								<router-link to="/userguide" class="sub_item">
									User Guide</router-link
								>
							</li>
							<li>
								<router-link to="/warning" class="sub_item"
									>Developer page</router-link
								>
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div class="navbar_menu2">
				<ul class="menu_cs">
					<li
						:class="{ active: isActive5 }"
						@click="showCsmenu"
						class="menu_cs--title"
					>
						Customer Service
						<ul class="menu_sub_list" v-show="csMenu">
							<li>
								<router-link to="/inquiry" class="sub_item"
									>Contact us</router-link
								>
							</li>
							<li>
								<router-link to="/faq" class="sub_item"
									>Frequently Asked Questions</router-link
								>
							</li>
						</ul>
					</li>
				</ul>

				<!-- 로그인 성공 시 헤더에 표시 및 분기처리 -->
				<!-- 1. 로그인 시 -->
				<template v-if="isUserlogin">
					<span
						class="mainheader__text--com"
						v-if="this.$store.state.userCom == 1"
						>Corporate Member
					</span>
					<span
						class="mainheader__text--personal"
						v-if="this.$store.state.userCom == 0"
						>individual member
					</span>
					<span class="mainheader__text--id">{{ $store.state.userid }}</span>
					<span>&nbsp;&nbsp; &nbsp;&nbsp;</span>
					<router-link to="/mypage" class="mainheader__text"
						>My Page</router-link
					>
					<span>&nbsp;｜&nbsp;</span>
					<a href="javascript:;" @click="logoutUser" class="mainheader__text"
						>Logout</a
					>
				</template>

				<!-- 2. 로그아웃 시 -->
				<template v-else>
					<router-link to="/login"
						><button id="btn-login" class="btn--sm btn--border-white">
							Login
						</button></router-link
					>
					<router-link to="/signup"
						><button id="btn-signup" class="btn--sm btn--fill-white">
							Sign Up
						</button></router-link
					>
				</template>
				<template>
					<div class="lang-select-box">
						<h3
							class="lang-select"
							:class="{ active: computedValue === 'ko' }"
							@click="changeLang('ko')"
						>
							KOR
						</h3>
						&nbsp; | &nbsp;
						<h3
							class="lang-select"
							:class="{ active: computedValue === 'en' }"
							@click="changeLang('en')"
						>
							ENG
						</h3>
					</div>
				</template>
			</div>
		</nav>
		<div class="global-overlay" v-show="menuActive"></div>
		<!-- 태블릿, 모바일에서의 navbar -->
		<nav class="navbar__mb">
			<div class="navbar_menu1">
				<div class="navbar_logo">
					<router-link to="/" class="logo"><img :src="logoUrl" /></router-link>
					<!-- 태블릿, 모바일에서의 햄버거 메뉴 -->
					<burger-menu
						@toggle-menu="menuActive = !menuActive"
						:active="menuActive"
					></burger-menu>
				</div>
				<div id="navbarNav" v-show="menuActive">
					<!-- 한글 / 영문 번역 추가 -->
					<template>
						<div class="lang-select-box">
							<h3
								class="lang-select"
								:class="{ active: computedValue === 'ko' }"
								@click="changeLang('ko')"
							>
								KOR
							</h3>
							&nbsp; | &nbsp;
							<h3
								class="lang-select"
								:class="{ active: computedValue === 'en' }"
								@click="changeLang('en')"
							>
								ENG
							</h3>
						</div>
					</template>
					<!--  -->

					<div class="navbar-nav">
						<div class="main_area">Service</div>
						<div class="sub_area">
							<router-link to="/service"><strong>Service</strong></router-link>
							<router-link to="/servicecrypto">Crypto Schema</router-link>
							<router-link to="/servicesecurity">Modulus</router-link>
							<router-link to="/serviceBiMining">BI Mining</router-link>
							<router-link to="/servicevaccine"
								>Vaccine FC-Anti Virus</router-link
							>
							<router-link to="/serviceCryptaLedger">CryptaLedger</router-link>
							<router-link to="/serviceData"
								>Data Encryption Vulnerability Analysis and
								Consulting</router-link
							>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">Solution</div>
						<div class="sub_area">
							<router-link to="/solutioncrypto">Crypto Schema</router-link>
							<router-link to="/solutionvaccine"
								>Vaccine FC-Anti Virus</router-link
							>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">Pricing</div>
						<div class="sub_area">
							<router-link to="/pricing"><strong>Pricing</strong></router-link>
							<!-- <router-link to="/pricingcrypto">암호 스키마</router-link> -->
							<router-link to="/pricingsecurity">Modulus</router-link>
							<router-link to="/pricingbimining">BI Mining</router-link>
							<!-- <router-link to="/pricingvaccine">백신 FC-Anti Virus</router-link> -->
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">Product Usag</div>
						<div class="sub_area">
							<router-link to="/userguide">User Guide</router-link>
							<router-link to="/warning">Developer Page</router-link>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">Modulus</div>
						<div class="sub_area">
							<router-link to="/warning">Modulus</router-link>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">Customer Center</div>
						<div class="sub_area">
							<router-link to="/inquiry">1:1 Contact</router-link>
							<router-link to="/faq">Frequently Asked Questions</router-link>
						</div>
					</div>

					<!-- 로그인 성공 시 헤더에 표시 및 분기처리 -->
					<!-- 1. 로그인 시 -->
					<template v-if="isUserlogin">
						<div class="login-info">
							<router-link to="/mypage" class="mainheader__text"
								>마이 페이지</router-link
							>
							<span>&nbsp;&nbsp;｜&nbsp;&nbsp;</span>
							<a
								href="javascript:;"
								@click="logoutUser"
								class="mainheader__text"
								>로그아웃</a
							>
						</div>
					</template>

					<!-- 2. 로그아웃 시 -->
					<template v-else>
						<div class="navbar_buttons">
							<router-link to="/login"
								><button id="btn__login" class="btn--extra btn--border-white">
									Login
								</button></router-link
							>
							<router-link to="/signup"
								><button id="btn__signup" class="btn--extra btn--fill-accent">
									Sign Up
								</button></router-link
							>
						</div>
					</template>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
import userinfoDataService from '../services/userinfoDataService';
import logoUrl from '@/assets/images/new-fctslogoWhite.svg';
import Burger from '@/components/common/Burger.vue';

export default {
	components: {
		'burger-menu': Burger,
	},
	data: function () {
		return {
			logoUrl,
			submenu1: null,
			submenu2: false,
			submenu3: false,
			submenu4: false,
			menuActive: false,
			sublistActive: false,
			csMenu: false,
			isActive: false,
			isActive2: false,
			isActive3: false,
			isActive4: false,
			isActive5: false,
		};
	},
	computed: {
		// 로그인 분기 처리
		isUserlogin() {
			return this.$store.getters.isLogin;
		},
		language() {
			return this.$store.state.language;
		},
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		showSubmenu1() {
			this.submenu1 = !this.submenu1;
			this.submenu2 = false;
			this.submenu3 = false;
			this.submenu4 = false;
			this.csMenu = false;
			this.isActive = !this.isActive;
			this.isActive2 = false;
			this.isActive3 = false;
			this.isActive4 = false;
			this.isActive5 = false;
		},
		showSubmenu2() {
			this.submenu2 = !this.submenu2;
			this.submenu1 = false;
			this.submenu3 = false;
			this.submenu4 = false;
			this.csMenu = false;
			this.isActive2 = !this.isActive2;
			this.isActive = false;
			this.isActive3 = false;
			this.isActive4 = false;
			this.isActive5 = false;
		},
		showSubmenu3() {
			this.submenu3 = !this.submenu3;
			this.submenu1 = false;
			this.submenu2 = false;
			this.submenu4 = false;
			this.csMenu = false;
			this.isActive3 = !this.isActive3;
			this.isActive2 = false;
			this.isActive = false;
			this.isActive4 = false;
			this.isActive5 = false;
		},
		showSubmenu4() {
			this.submenu4 = !this.submenu4;
			this.submenu1 = false;
			this.submenu2 = false;
			this.submenu3 = false;
			this.csMenu = false;
			this.isActive4 = !this.isActive4;
			this.isActive2 = false;
			this.isActive3 = false;
			this.isActive = false;
			this.isActive5 = false;
		},
		showCsmenu() {
			this.csMenu = !this.csMenu;
			this.submenu1 = false;
			this.submenu2 = false;
			this.submenu3 = false;
			this.submenu4 = false;
			this.isActive4 = false;
			this.isActive2 = false;
			this.isActive3 = false;
			this.isActive = false;
			this.isActive5 = !this.isActive5;
		},
		logoutUser() {
			// header logout
			this.$store.commit('clearUserid');
			userinfoDataService.removeStorage('user');
			userinfoDataService.removeStorage('vuex');
			location.reload();
		},
		changeLang(lang) {
			// console.log(lang);
			this.selectedLang = lang;
			sessionStorage.setItem('language', lang);
			this.$store.dispatch('changeLang', lang);
		},
		toggleActive() {
			this.isActive = !this.isActive; // 클릭할 때 active 상태를 토글
		},
	},
};
</script>

<style></style>
